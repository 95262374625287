import React from "react"
import SEO from "components/seo"

const DataProcessingAgreementPage = () => (
  <>
    <SEO
      title="Sourcery | Data Processing Agreement"
      description="Review Sourcery's Data Processing Agreement to understand how your data is handled securely and transparently."
    />

    <div className="bg-[#111111]">
      <div className="mx-auto px-6 pt-6 max-w-2xl">
        <h1 className="text-2xl text-left text-white 2xl:text-5xl md:text-4xl mt-28 lg:mt-40 lg:text-left">
          Data Processing Agreement
        </h1>
        <p className="text-base text-left text-white lg:text-left opacity-40 mt-5">
          Effective as of November 15, 2024
        </p>
      </div>

      <div className="px-6 py-6 mx-auto max-w-2xl text-white">
        <div>
          <p>
            This Data Processing Agreement (&quot;Agreement&quot;) is part of,
            and is governed by, the terms and conditions set forth in the Terms
            and Conditions of Sourcery.AI Limited (&quot;Sourcery&quot;). Terms
            not defined in this Agreement shall have the meaning given to them
            in the Terms and Conditions.
          </p>
          <p className="mt-5">
            If Sourcery makes any changes to this Agreement that materially
            affect how personal data is processed or reduce your rights, you
            will be notified (e.g., by email). If you have questions about this
            Agreement, wish to know more about our data protection practices, or
            need to exercise your rights regarding the processing of your
            personal data, contact our Data Protection Officer, Tim Gilboy, at{" "}
            <a href="mailto:privacy@sourcery.ai" className="underline">
              privacy@sourcery.ai
            </a>
            .
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">
            1. Definitions and Interpretation
          </h3>
          <p>
            Unless otherwise defined herein, terms and expressions in this
            Agreement shall have the meanings assigned in the GDPR.
          </p>
          <ul className="list-inside list-disc gap-3 mt-5">
            <li>
              <b>&quot;Agreement&quot;:</b> This Data Processing Agreement.
            </li>
            <li>
              <b>&quot;Client Personal Data&quot;:</b> Personal Data processed
              by Sourcery on behalf of the Client.
            </li>
            <li>
              <b>&quot;Data Processor&quot;:</b> Sourcery.AI Limited.
            </li>
            <li>
              <b>&quot;Data Controller&quot;:</b> The Client (any user of
              Sourcery services).
            </li>
            <li>
              <b>&quot;Services&quot;:</b> Code review and coding assistant
              services provided by Sourcery.
            </li>
            <li>
              <b>&quot;Subprocessor&quot;:</b> Any third party engaged by
              Sourcery to process personal data.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">2. Processing of Personal Data</h3>
          <p>
            <b>Roles and Scope:</b> Sourcery will process Client Personal Data
            solely to deliver the agreed Services, in accordance with documented
            instructions provided by the Client.
          </p>
          <p className="mt-5">
            <b>Nature of Processing:</b>
          </p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              <b>Data Types:</b> Name or GitHub/GitLab username, email address,
              credit card data (processed via Stripe), source code (not
              retained), and product usage data.
            </li>
            <li>
              <b>Categories of Data Subjects:</b> Customers and end users of the
              Sourcery platform.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">3. Processor Obligations</h3>
          <p>
            Sourcery shall comply with all applicable Data Protection Laws,
            ensuring that:
          </p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              Data is processed only as necessary to fulfill the Services.
            </li>
            <li>
              Access to data is restricted to authorized personnel under
              confidentiality agreements.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">4. Subprocessing</h3>
          <p>Sourcery may engage the following Subprocessors:</p>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>
              <b>Auth0:</b> User authentication and account management.
            </li>
            <li>
              <b>Langsmith:</b> Processes LLM message data and analytics.
            </li>
            <li>
              <b>Sentry:</b> Error handling and reporting.
            </li>
            <li>
              <b>Stripe:</b> Payment processing.
            </li>
            <li>
              <b>Mixpanel:</b> Product usage analytics.
            </li>
            <li>
              <b>Google Analytics:</b> Website analytics.
            </li>
          </ul>
          <p className="mt-5">
            All Subprocessors will comply with terms no less stringent than
            those set forth in this Agreement.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">5. Data Transfers</h3>
          <ul className="list-inside list-disc gap-3 mt-3">
            <li>Data storage is within the EEA.</li>
            <li>
              Data processed for LLM services may involve transfers outside the
              EEA, subject to Client configuration and choice of provider.
            </li>
          </ul>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">6. Data Subject Rights</h3>
          <p>
            Sourcery will assist the Client in responding to Data Subject
            requests, including access, rectification, or deletion of personal
            data. Sourcery shall not respond to such requests without Client
            instructions unless legally required.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">7. Data Breach Notification</h3>
          <p>
            Sourcery will notify the Client without undue delay upon becoming
            aware of a personal data breach, providing sufficient details for
            compliance with GDPR obligations.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl py-6 mt-3">8. Data Retention</h3>
          <p>
            Upon termination of the Services, all Client Personal Data will be
            deleted promptly unless required by law. Data deletion will be
            confirmed upon Client request.
          </p>
        </div>
      </div>
    </div>
  </>
)

export default DataProcessingAgreementPage
